<template>
    <v-container fluid>
        <v-form @submit.prevent="saveCity">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-subheader class="headline">{{ heading }}</v-subheader>
                            </v-col>
                            <v-col class="py-0" cols="12" md="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="city" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="city" type="text" :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-city-variant"
                                                  :label="$t('city_name')" color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="country" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="country" :error="!valid" :error-messages="errors"
                                              :disabled="loading" :items="countryItems"
                                              prepend-icon="mdi-flag-checkered" color="primary"
                                              item-text="name" item-value="id"
                                              :label="$t('country')">
                                    </v-select>
                                </ValidationProvider>

                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="timeZone" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="timeZone" :error="!valid" :error-messages="errors"
                                              :disabled="loading" :items="timeZoneItems"
                                              item-text="name" item-value="id"
                                              prepend-icon="mdi-clock" color="primary"
                                              :label="$t('time_zone')">
                                    </v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-6">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"

export default {
    name: 'CityForm',
    components: {
        ValidationProvider,
        ValidationObserver
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            city: null,
            country: 1,
            countryItems: [],
            timeZone: 'Asia/Almaty',
            timeZoneItems: [],
            exist_translations: {},
            all_translations: true,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.getTimeZones()
        this.getCountries()
        this.checkCreate()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "city.create") {
                this.heading = this.$t('city_creation')
            } else {
                this.heading = this.$t('city_editing')
                if (this.$route.params.id) {
                    this.getCity()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        async getTimeZones() {
            var _this = this
            await this.$http.get(
                `admin/timezone`,
            )
                .then(res => {
                    this.timeZoneItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_time_zones'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
        },
        async getCountries() {
            var _this = this
            let params = {}
            params.list = 'all'
            await this.$http.get(`admin/country`, {
                    params: params,
                }
            )
                .then(res => {
                    this.countryItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_countries'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
        },
        async getCity() {
            var _this = this
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/city/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.city = res.body.data.name
                    this.timeZone = res.body.data.timezone
                    this.country = res.body.data.country.id
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_city'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveCity() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.city) {
                formData.append('city', this.city)
            }
            if (this.country) {
                formData.append('country', this.country)
            }
            if (this.timeZone) {
                formData.append('timezone', this.timeZone)
            }


            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/city/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('city_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('city_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/city', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('city_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'city.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'city'
                            })
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('city_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
